import React, { lazy, Suspense } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase-config";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Loading from "../image/loading.gif";

const LandingPage = lazy(() => import("../Pages/LandingPage"));
const AboutUsPage = lazy(() => import("../Pages/AboutUsPage"));
const SectorPage = lazy(() => import("../Pages/SectorPage"));
const FeaturedBrandPage = lazy(() => import("../Pages/FeaturedBrandPage"));
const NewsPage = lazy(() => import("../Pages/NewsPage"));
const ContactUsPage = lazy(() => import("../Pages/ContctUsPage"));
const ProductView = lazy(() => import("../Pages/ProductView"));

const AdminDashboard = lazy(() => import("./../Admin/pages/AdminDashboard"));
const UserCredential = lazy(() => import("./../Admin/pages/UserCredential"));
const ForgotPassword = lazy(() => import("../Admin/pages/ForgotPassword"));
const SearchComp = lazy(() => import("./../Admin/components/SearchDataBox"));

function AnimatedRoute() {
  const [user] = useAuthState(auth);
  const location = useLocation();

  return (
    <AnimatePresence>
      <Suspense
        fallback={<img src={Loading} alt="loading" width="100%" high="100%" />}
      >
        <Routes location={location} key={location.pathname}>
          <Route path="/UPH" element={<LandingPage />}></Route>
          <Route index element={<LandingPage />}></Route>
          <Route path="/home" element={<LandingPage />}></Route>
          <Route path="/about-us-page" element={<AboutUsPage />}></Route>
          <Route path="/sectors-page" element={<SectorPage />}></Route>
          <Route
            path="/featured-brand-page"
            element={<FeaturedBrandPage />}
          ></Route>
          <Route path="/news-page" element={<NewsPage />}></Route>
          <Route path="/contact-us-page" element={<ContactUsPage />}></Route>
          <Route path="/product-view" element={<ProductView />}></Route>
          <Route
            path="/admin-uph"
            element={user ? <AdminDashboard /> : <UserCredential />}
          ></Route>
          <Route
            path="/admin-uph/forgot-password"
            element={<ForgotPassword />}
          ></Route>
          <Route path="/admin-uph/search" element={<SearchComp />}></Route>
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
}

export default AnimatedRoute;
