import React from "react";
import "./App.css";
import AnimatedRoute from "./Components/AnimatedRoute";
import "react-whatsapp-chat-widget/index.css";
function App() {
  return (
    <>
      <AnimatedRoute />
    </>
  );
}

export default App;
