// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  setDoc,
} from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import swal from "sweetalert";
import env from "react-dotenv";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: env.REACT_APP_FIREBASE_API_KEY,
  authDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.REACT_APP_FIREBASE_SENDER_ID,
  appId: env.REACT_APP_FIREBASE_APP_ID,
  measurementId: env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    swal({
      title: "Login Error",
      text: err.message,
      icon: "error",
      button: "Ok",
    });
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {    
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await setDoc(doc(db, "users", `${user.uid}`), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
      url: "https://firebasestorage.googleapis.com/v0/b/uph-site.appspot.com/o/profile.jpeg?alt=media&token=d0b0891c-a4c0-4734-a392-68df65ff025e",
      designation: "Not setup Yet",
    });    
    
  } catch (err) {
    swal({
      title: "Registration Error",
      text: err.message,
      icon: "error",
      button: "Ok",
    });
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    swal({
      title: "Password Reset",
      text: "Password reset link sent! May be its in spam list",
      icon: "success",
      button: "Ok",
    });
  } catch (err) {
    swal({
      title: "Password Reset",
      text: err.message,
      icon: "error",
      button: "Ok",
    });
  }
};

const logout = () => {
  signOut(auth);
};

export {
  db,
  storage,
  auth,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
